<template>
  <div class="documentation-wrapper">
    <div class="container">
      <h1 class="head-text">Документация</h1>

      <div>
        <h2>Руководство администратора</h2>
        <div class="content">
          <a
            href="https://back.way2wei.ru/storage/uploads/docs/Пользовательская_документация_администратор.docx"
            target="_blank"
            >Руководство пользователя для администратора</a
          >
        </div>
      </div>
      <div>
        <h2>Руководство пользователя</h2>
        <div class="content">
          <a
            href="https://back.way2wei.ru/storage/uploads/docs/Пользовательская_документация_пользователь.pdf"
            target="_blank"
            >Пользовательская документация</a
          >
        </div>
      </div>
      <div>
        <h2>Описание функциональных характеристик</h2>
        <div class="content">
          <a
            href="https://back.way2wei.ru/storage/uploads/docs/Документация,_содержащая_описание_функциональных_характеристик_экземпляра.pdf"
            target="_blank"
            >Функциональные характеристики</a
          >
        </div>
      </div>
      <div>
        <h2>Процессы жизненного цикла</h2>
        <div class="content">
          <a
            href="https://back.way2wei.ru/storage/uploads/docs/%D0%9E%D0%BF%D0%B8%D1%81%D0%B0%D0%BD%D0%B8%D0%B5_%D0%BF%D1%80%D0%BE%D1%86%D0%B5%D1%81%D1%81%D0%BE%D0%B2_%D0%BE%D0%B1%D0%B5%D1%81%D0%BF%D0%B5%D1%87%D0%B8%D0%B2%D0%B0%D1%8E%D1%89%D0%B8%D1%85_%D0%BF%D0%BE%D0%B4%D0%B4%D0%B5%D1%80%D0%B6%D0%B0%D0%BD%D0%B8%D0%B5_%D0%B6%D0%B8%D0%B7%D0%BD%D0%B5%D0%BD%D0%BD%D0%BE%D0%B3%D0%BE_%D1%86%D0%B8%D0%BA%D0%BB%D0%B0_%D0%9F%D0%9E.docx"
            target="_blank"
            >Описание процессов, обеспечивающих поддержание жизненного цикла
            ПО</a
          >
        </div>
      </div>
      <div>
        <h2>Стоимость</h2>
        <div class="content">
          <button @click="openPriceModal = true">Стоимость</button>
        </div>
      </div>
    </div>
    <pop-up-form
      title="Обсудить проект. Оставьте заявку!"
      type="Default"
      v-if="openPriceModal"
      @closeModal="openPriceModal = false"
    />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import PopUpForm from "../../components/pop-up-form/PopUpForm.vue";
export default {
  components: { PopUpForm },
  name: "documentation",
  setup() {
    const openPriceModal = ref(false);

    return { openPriceModal };
  },
};
</script>

<style scoped lang="scss">
.documentation-wrapper {
  width: 100%;
  padding: 121px 25px 50px;
  font-size: 17px;
  line-height: 24px;
  color: #414352;

  @media (max-width: 767px) {
    padding: 25px 15px 5px;
  }
}

h2 {
  font-size: 24px;
  line-height: 1.2;
  margin: 10px 0 15px;
  position: relative;
  line-height: 150%;
  padding: 20px 15px 20px 0;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: 300;
  text-decoration: underline;
  margin-left: 0;
  padding-left: 0;
}

a {
  text-decoration: underline;
  color: #000;
}
.container {
  position: relative;
  margin: 0 auto;
  padding-top: 25px;
  max-width: 1170px;

  @media (max-width: 767px) {
    padding: 35px 15px;
  }
}
</style>